
import { OverlayContentComponent } from '@acaprojects/ngx-widgets';
import { Component } from '@angular/core';

import { HashMap } from '../../shared/utilities/types.utilities';

interface SpaceJoinStatus {
    /** System ID of the space initiating the join */
    initiator: string;
    /** List of spaces that are linked */
    rooms: string[];
}

interface LinkableSpace {
    /** System ID of the space */
    id: string;
    /** Name of the space */
    name: string;
    /** Whether space is joined to another */
    is_joined: boolean;
    /** Whether space is joined with the current system */
    is_in_group?: boolean;
}

@Component({
    selector: 'join-room-modal',
    templateUrl: './join-room.template.html',
    styleUrls: ['./join-room.styles.scss']
})
export class JoinRoomModalComponent extends OverlayContentComponent {

    /** System to bind to */
    public system: string;
    /** Mapping of system IDs to their names */
    public spaces: HashMap<string>;
    /** List of spaces to join */
    public join_list: string[];
    /** Details about the joined spaces */
    public link_status: SpaceJoinStatus;
    /** Whether space is linked to another */
    public is_joined: boolean;
    /** Toggle for linked state */
    public exec_unlink: boolean;
    /** Toggle for linked state */
    public exec_join: boolean;
    /** List of space */
    public space_list: LinkableSpace[] = [];

    public init() {
        this.service.listen('system', (sys) => this.system = sys);
    }

    /** Number of spaces joined/linked to the current space */
    public get connected_spaces(): number {
        return this.space_list.reduce((count, space) => count + (space.is_in_group ? 1 : 0), 0);
    }

    /** Whether system is joined to other spaces */
    public get joined(): boolean {
        return this.is_joined && this.link_status && !!this.link_status.rooms;
    }

    /** Update the list of spaces available for joining */
    public updateSpaceList() {
        const spaces = [];
        for (const id in this.spaces) {
            if (id !== this.system){
                spaces.push({
                    id,
                    name: this.spaces[id],
                    is_joined: this.link_status && this.link_status.rooms && !!this.link_status.rooms.find(rm => rm === id),
                    is_in_group: this.link_status && this.link_status.rooms && !!this.link_status.rooms.find(rm => rm === id),
                });
            }
        }
        this.space_list = spaces;
    }

    public join() {
        if (this.space_list) {
            this.join_list = [];
            for (const rm of this.space_list) {
                if (rm.id !== this.system && rm.is_in_group) {
                    this.join_list.push(rm.id);
                }
            }
            if (this.join_list && this.join_list.length > 0) {
                this.exec_join = !this.exec_join;
            }
        }
    }

    public unlink() {
        this.exec_unlink = !this.exec_unlink;
    }

    public event(name: string) {
        setTimeout(() => this.fn.event(name), 300);
    }
}
