
import { OverlayContentComponent } from '@acaprojects/ngx-widgets';
import { Component } from '@angular/core';

@Component({
    selector: 'mics-modal',
    templateUrl: './mics.template.html',
    styleUrls: ['./mics.styles.scss']
})
export class MicrophoneModalComponent extends OverlayContentComponent {
    public mics: any[] = [];
    public vol_min: number = 0;
    public vol_max: number = 100;

    public sort() {
        console.log('Model:', this.model);
        if (this.model.levels) {
            this.model.levels.sort((a, b) => a.level - b.level);
        }
    }

    public select(item: any) {
        this.model.active = item.id;
        this.fn.close();
    }

    public init() {
        console.log('Mics:', this.mics);
        this.model.loading = true;
        this.service.listen('system', (s) => this.model.system = s);
        if (this.mics) {
            for (const m of this.mics) {
                m.mute = false;
                m.fader = `fader${m.mixer_id}${m.mixer_index ? '_' + m.mixer_index : ''}${m.component ? '_' + m.component : ''}`;
                m.fader_mute = `fader${m.mute_id || m.mixer_id}${m.mixer_index ? '_' + m.mixer_index : ''}${m.component ? '_' + m.component : ''}`;
                m.volume = 0;
                if (m.routing) {
                    for (const r of m.routing) {
                        r.mute = false;
                    }
                }
            }
        }
        console.log('Mics:', this.mics);
        this.model.loading = false;
    }
}
