import { OverlayContentComponent } from '@acaprojects/ngx-widgets';
import { Component } from '@angular/core';

import { format } from 'date-fns';

@Component({
    selector: 'booking-modal',
    template: `
        <div class="bg-white rounded overflow-hidden">
            <header class="flex items-center">
                <h3 class="m-2">Call Bookings</h3>
                <button icon (touchrelease)="fn.close()">
                    <i class="fa fa-close"></i>
                </button>
            </header>
            <main *ngIf="current || next; else empty_state">
                <div event class="flex items-center p-2 w-full" *ngIf="current">
                    <div class="flex flex-col flex-1">
                        <div class="">{{ current.title || 'Meeting' }}</div>
                        <div class="text-sm opacity-60">{{ current.host }}</div>
                        <div class="text-xs opacity-60">{{ period(current) }}</div>
                    </div>
                    <button style="background-color: #388e3c" (click)="dial_number = !dial_number; onDial()" *ngIf="joinable(current)">
                        Join
                    </button>
                </div>
                <div event class="flex items-center p-2 w-full" *ngIf="next; else empty_next_state">
                    <div class="flex flex-col flex-1">
                        <div class="">{{ next.title || 'Meeting' }}</div>
                        <div class="text-sm opacity-60">{{ next.host }}</div>
                        <div class="text-xs opacity-60">{{ period(next) }}</div>
                    </div>
                    <button style="background-color: #388e3c" (click)="next_number = !next_number; onDial()" *ngIf="joinable(next)">
                        Join
                    </button>
                </div>
            </main>
        </div>
        <ng-template #empty_state>
            <main class="flex flex-col items-center justify-center" empty>
                <p>No upcoming events for today</p>
            </main>
        </ng-template>
        <ng-template #empty_next_state>
            <div event class="flex items-center justify-center p-8 w-full">
                <p class="opacity-60">No upcoming events for today</p>
            </div>
        </ng-template>
        <div hidden>
            <i binding [(value)]="current" [sys]="system_id" mod="VidConf_1" bind="booking_current"></i>
            <i binding [(value)]="next" [sys]="system_id" mod="VidConf_1" bind="booking_next"></i>
            <i binding [(value)]="count" [sys]="system_id" mod="VidConf_1" bind="additional_bookings"></i>
            <i
                binding
                [value]="dial_number"
                [sys]="system_id"
                mod="VidConf_1"
                exec="dial"
                [params]="[current ? current.dial_info_calls_call1_number : '']"
            ></i>
            <i
                binding
                [value]="next_number"
                [sys]="system_id"
                mod="VidConf_1"
                exec="dial"
                [params]="[next ? next.dial_info_calls_call1_number : '']"
            ></i>
        </div>
    `,
    styles: [
        `
            header {
                background-color: #37474f;
                color: #fff;
                justify-content: space-between;
                width: 32rem;
                max-width: calc(100vw - 2rem);
            }

            [empty] {
                padding: 4rem;
                opacity: 0.3;
            }

            [event] {
                border-bottom: 1px solid #ccc;
            }
        `,
    ],
})
export class JoinBookingModalComponent extends OverlayContentComponent {
    public current = null;
    public next = null;
    public count = 0;
    public dial_number = false;
    public next_number = false;
    public system_id = '';

    public init() {
        this.service.listen('system', (sys) => (this.system_id = sys));
    }

    public period(data: any) {
        if (!data) return '';
        return `${format(data.time_start_time * 1000 || Date.now(), 'h:mm a')} - ${format(
            data.time_end_time * 1000 || Date.now(),
            'h:mm a'
        )}`;
    }

    public onDial() {
        setTimeout(() => this.fn.close(), 300);
    }

    public joinable(meeting: any) {
        var start_time = (meeting.time_start_time - (meeting.time_start_time_buffer || 0)) * 1000
        var end_time = (meeting.time_end_time + (meeting.time_end_time_buffer || 0)) * 1000
        var now = Date.now()
        return start_time <= now && now <= end_time
    }
}
