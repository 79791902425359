
import { Routes } from '@angular/router';
import { BootstrapComponent } from './bootstrap/bootstrap.component';
import { AppShellComponent } from './shell/shell.component';
import { SystemPanelSelectComponent } from './shell/panel-select/panel-select.component';

export const ROUTES: Routes = [
    { path: 'bootstrap', component: BootstrapComponent },
    { path: 'panel-select', component: SystemPanelSelectComponent },
    { path: 'panel', component: AppShellComponent },
    { path: 'panel/:sys_id', component: AppShellComponent },
    { path: 'panel/:sys_id/:page', component: AppShellComponent },
    { path: '**',      redirectTo: 'bootstrap' },
];
