import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { HashMap } from '../../shared/utilities/types.utilities';
import { AppService } from '../../services/app.service';
import { BaseComponent } from '../../shared/base.component';

@Component({
    selector: 'app-panel-select',
    templateUrl: './panel-select.component.html',
    styleUrls: ['./panel-select.component.scss']
})
export class SystemPanelSelectComponent extends BaseComponent implements OnInit {
    /** List of systems to show panels for */
    public systems: string[];
    /** Names for the list of systems */
    public system_name: HashMap<string> = {};
    /** Icons for the list of systems */
    public system_icon: HashMap<string> = {};
    /** Status for the list of systems */
    public system_status: HashMap<string> = {};
    /** Active Panel system */
    public active_system: string;
    /** Countdown counter */
    public countdown: number;

    constructor(private route: ActivatedRoute, private service: AppService) {
        super();
    }

    public ngOnInit(): void {
        this.subscription('route.query', this.route.queryParamMap.subscribe((params) => {
            if (params.has('system_ids')) {
                this.systems = (params.get('system_ids') || '').split(',');
            }
        }));
    }

    /**
     * Show booking panel for the given system ID
     * @param id ID of the system to show
     */
    public showPanel(id: string) {
        this.active_system = id;
        this.service.set('system', id);
    }

    /**
     * Close the modal
     */
    public close() {
        this.active_system = null;
        this.service.set('system', null);
        this.clearInterval('countdown');
    }

    /**
     * Decrement countdown and close if 0
     */
    public tick() {
        if (this.countdown <= 0) {
            this.close();
        }
        this.countdown--;
    }
}
