
import { OverlayContentComponent } from '@acaprojects/ngx-widgets';
import { Component } from '@angular/core';

@Component({
    selector: 'help-video-modal',
    templateUrl: './help-video.template.html',
    styleUrls: ['./help-video.styles.scss']
})
export class HelpVideoModalComponent extends OverlayContentComponent {
    public clearVideo() {
        setTimeout(() => this.model.video = null, 300);
    }
}
