/**
 * @Author: Alex Sorafumo <alex.sorafumo>
 * @Date:   11/01/2017 4:16 PM
 * @Email:  alex@yuion.net
 * @Filename: mock-system.ts
 * @Last modified by:   Alex Sorafumo
 * @Last modified time: 03/02/2017 2:26 PM
 */

import { MOCK_REQ_HANDLER } from '@acaprojects/ngx-composer';

import { Utils } from '../utility.class';

import * as moment from 'moment';
import * as faker from 'faker';

const win = self as any;
const search = [
    {
        contents: [
            {
                methods: [
                    {
                        contactmethodid: '1',
                        number: 'SIP:36910@conference.deakin.edu.au',
                        protocol: 'SIP',
                    },
                ],
                name: '**Test Call VMP (SIP)',
                contactid: 'e_11388',
                count: 63,
            },
        ],
        local_id: 'c_2',
        folder_id: 'c_2',
        name: 'Test Support',
    },
    {
        contents: [],
        local_id: 'c_6',
        folder_id: 'c_6',
        name: 'Technical Support',
    },
];

// const search = [{
//     'methods': [{ 'contactmethodid': '1', 'number': 'SIP:39308@deakin.edu.au', 'protocol': 'SIP', 'callrate': '4096' }],
//     'name': 'GTP ACES Burwood VMP (SIP) with a very long suffix',
//     'contactid': 's_1'
// }, {
//     'methods': [{ 'contactmethodid': '1', 'number': 'SIP:39306@deakin.edu.au', 'protocol': 'SIP', 'callrate': '4096' }],
//     'name': 'Panel tomorros',
//     'contactid': 's_1'
// }, {
//     'methods': [{ 'contactmethodid': '1', 'number': 'SIP:39307@deakin.edu.au', 'protocol': 'SIP', 'callrate': '4096' }],
//     'name': 'Panel 32',
//     'contactid': 's_1'
// }, {
//     'methods': [{ 'contactmethodid': '1', 'number': 'SIP:39305@deakin.edu.au', 'protocol': 'SIP', 'callrate': '4096' }],
//     'name': 'Panel 5',
//     'contactid': 's_1'
//     }];

// for (let i = 0; i < 1000; i++) {
//     search.push({
//         'methods': [{
//             'contactmethodid': '1',
//             'number': `SIP:${Math.floor(Math.random() * 89999 + 10000)}@deakin.edu.au`,
//             'protocol': 'SIP',
//             'callrate': '4096'
//         }],
//         'name': `${faker.name.firstName()} ${faker.name.lastName()}`,
//         'contactid': 's_1'
//     })
// }

win.systemData = win.systemData || {};
win.control = win.control || {};
win.control.systems = win.control.systems || {};
win.control.systems['sys-B0'] = {
    System: [
        {
            name: 'Demo System',
            state: 'shutdown',
            volume_min: 0,
            volume_max: 1,
            volume_step: 0.001,
            mode: '',
            $powerup: function (mode?: string) {
                this.$system.System[0].state = 'online';
                this.$system.System[0].mode = 'changing';
                setTimeout(() => (this.$system.System[0].mode = mode), 10);
            },
            $shutdown: function () {
                this.$system.System[0].state = 'shutdown';
            },
            sources: [
                { name: 'Off', icon: 'ban' },
                { name: 'Desktop', icon: 'desktop' },
                { name: 'Laptop', icon: 'laptop' },
                { name: 'Camera 1', icon: 'video-camera' },
                { name: 'Camera 2', icon: 'video-camera', preview: false },
                { name: 'Visualiser', icon: 'visualiser' },
                {
                    name: 'Pods',
                    icon: 'list',
                    children: [
                        { name: 'Pod 1', icon: 'desktop' },
                        { name: 'Pod 2', icon: 'desktop' },
                        { name: 'Pod 3', icon: 'desktop' },
                        { name: 'Pod 4', icon: 'desktop' },
                        { name: 'Pod 5', icon: 'desktop' },
                        { name: 'Pod 6', icon: 'desktop' },
                        { name: 'Pod 7', icon: 'desktop' },
                        { name: 'Pod 8', icon: 'desktop' },
                    ],
                },
            ],
            outputs: [
                { name: 'Projector 1', recording: true, disable_show: true },
                { name: 'Projector 2', recording: false },
                { name: 'Projector 3', recording: false },
                { name: 'Projector 4', recording: false },
                { name: 'Projector 5', recording: false, hidden: true },
                // { name: 'Preview Left', recording: false, hidden: true },
                // { name: 'Preview Right', recording: false, hidden: true }
            ],
            routes: {
                'Projector 1': { input: 'Laptop', muted: false },
                'Projector 2': { input: 'Desktop', muted: true },
                'Projector 3': { input: 'Camera 1', muted: false },
                'Projector 4': { input: 'Camera 2', muted: true },
                // 'Projector 5': { input: 'Camera 2', muted: true },
                preview: { input: 'Off', muted: true },
                'Preview Left': { input: 'Off', muted: true },
                'Preview Right': { input: 'Off', muted: true },
            },
            pc_av: {
                sources: [{ input: 'Desktop', output: 'Projector 2' }],
                enabled: false,
            },
            cameras: [
                {
                    title: 'Rear Right Camera',
                    mod: 'Camera_1',
                },
                {
                    title: 'Front Right Camera',
                    mod: 'Camera_2',
                    auto_camera: false,
                },
                {
                    title: 'Front Left Camera',
                    mod: 'Camera_3',
                },
                {
                    title: 'Rear Left Camera',
                    mod: 'Camera_4',
                    auto_camera: false,
                },
            ],
            help_message: 'This is a test help message for all who acknowledge my awesomeness!',
            help_videos: [
                {
                    name: 'Introduction',
                    src: 'http://aca.engin.run/docs/widgets/assets/small.webm',
                },
                {
                    name: 'Introduction 2',
                    src: 'http://aca.engin.run/docs/widgets/assets/small.webm',
                },
                {
                    name: 'Introduction 3',
                    src: 'http://aca.engin.run/docs/widgets/assets/small.webm',
                },
            ],
            $present: function (input: string, output: string) {
                const old_route = this.$system.System[0].routes[output];
                const routes = JSON.parse(JSON.stringify(this.$system.System[0].routes));
                routes[output] = { input, muted: old_route.muted };
                this.$system.System[0].routes = routes;
            },
            $output_mute: function (output: string, state: boolean) {
                const old_route = this.$system.System[0].routes[output];
                const routes = JSON.parse(JSON.stringify(this.$system.System[0].routes));
                routes[output] = { input: old_route.input, muted: state };
                this.$system.System[0].routes = routes;
            },
            $set_pc_av: function (state: boolean) {
                const old_pc_av = this.$system.System[0].pc_av;
                const pc_av = { ...old_pc_av };
                pc_av.enabled = state;
                this.$system.System[0].pc_av = pc_av;
            },
            audio_routes: [
                {
                    title: 'Bar Wall Plate',
                    mixer_id: 'PGM4:Level',
                    mute_id: 'PGM4:Mute',
                    component: 'W-H1-20-CS',
                    min: 0,
                    max: 1,
                    step: 0.001,
                    routing: [
                        {
                            zone_name: 'H1.20',
                            component: 'W-H1-20-PGM',
                            zone_ids: ['PGM4:Select 1'],
                        },
                        {
                            zone_name: 'H1.20A',
                            component: 'W-H1-20-PGM',
                            zone_ids: ['PGM4:Select 2'],
                        },
                        {
                            zone_name: 'Bar',
                            component: 'W-H1-20-PGM',
                            zone_ids: ['PGM4:Select 3'],
                        },
                    ],
                },
            ],
            rooms: {
                'sys-B0': 'Demo System',
                'sys-B1': 'Meeting Room 1',
                'sys-B2': 'Meeting Room 2',
            },
            joined: {
                initiator: 'sys-B0',
                rooms: ['sys-B0'],
            },
            is_joined: false,
            $join: function (rooms: string[]) {
                this.$system.System[0].joined = {
                    initiator: 'sys-B0',
                    rooms: ['sys-B0', ...rooms],
                };
                this.$system.System[0].is_joined = true;
                for (const id of rooms) {
                    win.control.systems[id].System[0].joined = {
                        initiator: 'sys-B0',
                        rooms: ['sys-B0', ...rooms],
                    };
                    win.control.systems[id].System[0].is_joined = true;
                }
            },
            $unjoin: function () {
                const rooms = this.$system.System[0].joined.rooms;
                this.$system.System[0].joined = {
                    initiator: 'sys-B0',
                    rooms: ['sys-B0'],
                };
                for (const id of rooms) {
                    win.control.systems[id].System[0].joined = {
                        initiator: id,
                        rooms: [id],
                    };
                    win.control.systems[id].System[0].is_joined = false;
                }
            },
            light_levels: [
                { name: 'Off', level: 0, id: 'off' },
                { name: 'Showcase', level: 0.6, id: 'showcase' },
                { name: 'Full', level: 1, id: 'full' },
                { name: 'Presentation', level: 0.2, id: 'presentation' },
                { name: 'Office', level: 0.8, id: 'officon' },
            ],
            light_level: 'off',
            volume: 0.123,
            vol_feedback: {
                device: 'Mixer_1',
                mute: 'mute:234',
                level: '234:level',
            },
            preview: {},
            // For 2 previews bars
            // preview: ['Preview Left', 'Preview Right'],
            mute: true,
            mic_feedback: {
                device: 'Mixer_1',
                status: 'faderGMIC:Mute_B-LC4-100-CS_mute',
                id: 'GMIC:Mute',
                index: 'B-LC4-100-CS',
            },
            $mic_mute: function (state: boolean) {
                this.$system.System[0].mute = state;
            },
            modes: [
                {
                    id: 'presentation',
                    title: 'Start Presentation',
                },
                {
                    id: 'vidconf',
                    title: 'Start Video Conference',
                },
            ],
            camera_selected: 'Front Right Camera',
            $select_camera: function (camera: any) {
                this.$system.System[0].camera_selected = camera.title || camera.name;
            },
        },
    ],
    VidConf: [
        {
            connected: true,
            presentation: 'local',
            $presentation_mode: function (type: string) {
                this.$system.VidConf[0].presentation = type;
            },
            $search: function (filter: string) {
                this.$system.VidConf[0].searching = true;
                this.$system.VidConf[0].search_results = Utils.filter(filter, search, ['name', 'number']);
                setTimeout(() => (this.$system.VidConf[0].searching = false), 2000);
            },
            $call: function (state: string) {
                this.incall = state === 'accept';
            },
            camera_pip: true,
            complete_phonebook: search,
            incall: false,
            search_results: [],
            searching: false,
            call_status: {},
        },
    ],
    Capture: [
        {
            $pause: function () {
                if (this.$system.Capture[0].current) {
                    const current = { ...this.$system.Capture[0].current, state: 'paused' };
                    this.$system.Capture[0].current = current;
                }
                console.log('Paused:', this.$system.Capture[0].current);
            },
            $resume: function () {
                if (this.$system.Capture[0].current) {
                    const current = { ...this.$system.Capture[0].current, state: 'active' };
                    this.$system.Capture[0].current = current;
                }
                console.log('Resume:', this.$system.Capture[0].current);
            },
            $stop: function () {
                if (this.$system.Capture[0].current) {
                    this.$system.Capture[0].current = null;
                }
                console.log('Stop:', this.$system.Capture[0].current);
            },
            next: {
                start_time: moment().toISOString(),
            },
            time_remaining: 10 * 60 * 60 + 32 * 60 + 22,
            title: 'Test Title',
            current: {
                start_time: moment().toISOString(),
                state: 'paused',
                title: 'Unit: SRC362 Documentation Studio',
            },
        },
    ],
    Camera: [
        {
            presets: ['Test 1', 'Preset', 'Default', 'Test 2', 'Preset 2', 'Reset', 'Another Preset', 'Test Overflow'],
            zoom_min: 10,
            zoom_max: 200,
            $camera_position_add: function (name: string) {
                this.presets = [...this.presets, name];
            },
        },
        {
            presets: ['Test 1', 'Preset', 'Default'],
            zoom_min: 150,
            zoom_max: 500,
            $camera_position_add: function (name: string) {
                this.presets = [...this.presets, name];
            },
        },
    ],
    Lighting: [
        {
            $light_level: function (args) {
                console.log('Light Level changed', args);
            },
        },
    ],
    Blinds: [
        {
            controls: {
                blackout: {
                    module: 'Lighting_1',
                    up: {
                        func: 'light_level',
                        args: [35, 255, 1],
                    },
                    down: {
                        func: 'light_level',
                        args: [35, 0, 1],
                    },
                },
                sheers: {
                    module: 'Lighting_1',
                    up: {
                        func: 'light_level',
                        args: [35, 255, 0],
                    },
                    down: {
                        func: 'light_level',
                        args: [35, 0, 0],
                    },
                },
            },
        },
    ],
};
win.control.systems['sys-B1'] = {
    System: [
        {
            is_joined: false,
        },
    ],
};
win.control.systems['sys-B2'] = {
    System: [
        {
            is_joined: true,
        },
    ],
};

for (const k in win.control.systems) {
    if (win.control.systems.hasOwnProperty(k)) {
        win.systemData[k] = win.control.systems[k];
    }
}
