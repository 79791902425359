/**
 * @Author: Alex Sorafumo <alex.sorafumo>
 * @Date:   12/01/2017 2:25 PM
 * @Email:  alex@yuion.net
 * @Filename: app.service.ts
 * @Last modified by:   Alex Sorafumo
 * @Last modified time: 03/02/2017 10:25 AM
 */

import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { SystemsService } from '@acaprojects/ngx-composer';
import { OverlayService, NotificationComponent } from '@acaprojects/ngx-widgets';

import Agent from '@meldcx/agent';

import { SettingsService } from './settings.service';
import { Utils } from '../shared/utility.class';
import { SwUpdate } from '@angular/service-worker';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

import { SelectSourceModalComponent } from '../popups/select-source/select-source.component';
import { ChangeLightingModalComponent } from '../popups/change-lighting/change-lighting.component';
import { ConfirmModalComponent } from '../popups/confirm/confirm.component';
import { MicrophoneModalComponent } from '../popups/mics/mics.component';
import { JoinRoomModalComponent } from '../popups/join-room/join-room.component';
import { AnalyticsService } from './analytics.service';
import { CameraControlModalComponent } from '../popups/camera-control/camera-control.component';
import { HelpVideoModalComponent } from '../popups/help-video/help-video.component';
import { DialDirectoryModalComponent } from '../popups/dial-directory-modal/dial-directory-modal.component';
import { BlindsModalComponent } from '../popups/blinds/blinds.component';
import { JoinBookingModalComponent } from '../popups/bookings-modal.component';
import { DialingModalComponent } from '../popups/dialing-modal.component';

@Injectable()
export class AppService {
    private api_base = 'app_api';

    private _system = '';
    private subjects: any = {};
    private observers: any = {};

    private prev_route: string[] = [];
    private model: any = {};
    private timers: any = {};

    constructor(
        private _title: Title,
        private version: SwUpdate,
        private router: Router,
        private overlay: OverlayService,
        private settings: SettingsService,
        private analytics: AnalyticsService,
        // private comms: CommsService
        private systems: SystemsService
    ) {
        NotificationComponent.timeout(3000);
        this.init();
        this.subjects.system = new BehaviorSubject('');
        this.observers.system = this.subjects.system.asObservable();
        this.subjects.systems = new BehaviorSubject<any[]>([]);
        this.observers.systems = this.subjects.systems.asObservable();
        this.overlay.registerService(this);
        this.overlay.setupModal('select-source', { cmp: SelectSourceModalComponent });
        this.overlay.setupModal('change-lighting', { cmp: ChangeLightingModalComponent });
        this.overlay.setupModal('confirm', { cmp: ConfirmModalComponent });
        this.overlay.setupModal('mics', { cmp: MicrophoneModalComponent });
        this.overlay.setupModal('join-rooms', { cmp: JoinRoomModalComponent });
        this.overlay.setupModal('cameras', { cmp: CameraControlModalComponent });
        this.overlay.setupModal('help-video', { cmp: HelpVideoModalComponent });
        this.overlay.setupModal('dial-directory', { cmp: DialDirectoryModalComponent });
        this.overlay.setupModal('blinds', { cmp: BlindsModalComponent });
        this.overlay.setupModal('dialpad', { cmp: DialingModalComponent });
        this.overlay.setupModal('join-bookings', { cmp: JoinBookingModalComponent });
        this.model.agent = new Agent();
    }

    get endpoint() {
        const host = this.Settings.get('composer.domain');
        const protocol = this.Settings.get('composer.protocol');
        const port = protocol === 'https:' ? '443' : '80';
        const url = `${protocol || location.protocol}//${host || location.host}`;
        const endpoint = `${url}`;
        return endpoint;
    }

    get api_endpoint() {
        return `${this.endpoint}/${this.api_base}`;
    }

    public init() {
        if (!this.settings.setup) {
            return setTimeout(() => this.init(), 500);
        }
        this.version.available.subscribe((event) => {
            this.settings.log(
                'CACHE',
                `Update available: current version is ${event.current.hash} available version is ${event.available.hash}`
            );
            this.info('Newer version of the app is available', 'Refresh');
        });
        this.version.activated.subscribe((event) => {
            console.log('Activated service worker');
        });
        this.model.title = this.settings.get('app.title') || 'Angular Application';
        this.initialiseComposer();
        this.loadSystems();
        setInterval(() => this.checkCache(), 5 * 60 * 1000);
    }

    public initialiseComposer(tries: number = 0) {
        this.settings.log('SYSTEM', 'Initialising Composer...');
        // Get domain information for configuring composer
        const host = this.settings.get('composer.domain') || location.hostname;
        const protocol = this.settings.get('composer.protocol') || location.protocol;
        const port = protocol.indexOf('https') >= 0 ? '443' : '80';
        const url = this.settings.get('composer.use_domain') ? `${protocol}//${host}` : location.origin;
        const route = this.settings.get('composer.route') || '';
        // Generate configuration for composer
        const config: any = {
            id: 'AcaEngine',
            scope: 'public',
            protocol,
            host,
            port,
            oauth_server: `${url}/auth/oauth/authorize`,
            oauth_tokens: `${url}/auth/token`,
            redirect_uri: `${location.origin}${route}/oauth-resp.html`,
            api_endpoint: `${url}/control/`,
            proactive: true,
            login_local: this.settings.get('composer.local_login') || false,
            http: true,
        };
        // Enable mock/development environment if the settings is defined
        const mock = this.settings.get('mock');
        if (mock) {
            config.mock = true;
            config.http = false;
        }
        // Setup/Initialise composer
        this.systems.setup(config);
    }

    public initSystem(sys: string) {
        this._system = sys;
        if (!this._system || this._system === '') {
            if (localStorage) {
                this._system = localStorage.getItem('ACA.CONTROL.system');
                if (this.subjects.system) {
                    this.subjects.system.next(this._system);
                }
            }
            if (!this._system || this._system === '') {
                this.model.agent
                    .onReadyAsync()
                    .then(() => {
                        this.model.agent.Storage.getItem('BOOKINGS.system')
                            .then((value) => {
                                this._system = value;
                                this.subjects.system.next(this._system);
                            })
                            .catch(() => this.navigate('bootstrap'));
                    })
                    .catch(() => this.navigate('bootstrap'));
            } else {
                this.navigate('');
            }
        } else {
            if (this.subjects.system) {
                this.subjects.system.next(this._system);
            }
            this.model.agent
                .onReadyAsync()
                .then(() => {
                    this.model.agent.Storage.setItem('BOOKINGS.system', this._system);
                })
                .catch(() => null);
        }
    }

    public listen(name: string, next: (data: any) => void) {
        if (this.subjects[name]) {
            return this.observers[name].subscribe(next);
        }
        return null;
    }

    public get(name: string) {
        if (this.subjects[name]) {
            return this.subjects[name].getValue();
        }
        return null;
    }

    public set<T>(name: string, value: T) {
        if (this.subjects[name]) {
            return this.subjects[name].next(value);
        }
        return null;
    }

    get Settings() {
        return this.settings;
    }
    get Systems() {
        return this.systems;
    }
    get Overlay() {
        return this.overlay;
    }
    get Analytics() {
        return this.analytics;
    }
    get system() {
        return this.subjects.system.getValue();
    }
    set system(value: string) {
        console.log('Setting System:', value);
        this.subjects.system.next(value);
    }

    set title(str: string) {
        if (!this.model.title) {
            this.model.title = this.settings.get('app.title') || '';
        }
        const title = `${str ? str : ''}${this.model.title ? ' | ' + this.model.title : ''}`;
        this._title.setTitle(title || this.settings.get('app.title'));
    }

    public navigate(path: string, query?: any) {
        const path_list = [];
        path_list.push(path);
        this.prev_route.push(this.router.url);
        // if (!this.systems.resources.authLoaded) {
        this.router.navigate(path_list, { queryParams: query });
        // } else {
        // this.router.navigate([path]);
        // }
    }

    public back() {
        if (this.prev_route.length > 0) {
            this.navigate(this.prev_route.pop());
            this.prev_route.pop();
        } else {
            this.navigate('');
        }
    }

    public log(type: string, msg: string, args?: any, stream: string = 'debug') {
        this.settings.log(type, msg, args, stream);
    }

    /**
     * Create a new error notification
     * @param msg Message to display
     * @param action User action to display
     * @param event Callback for user action
     */
    public error(msg: string, action?: string, event?: () => void) {
        const message = msg ? msg : `Error`;
        this.overlay.notify('success', {
            html: `<div class="display-icon error" style="font-size:2.0em"></div><div class="msg">${message}</div>`,
            name: 'ntfy error',
            action,
        });
    }

    /**
     * Create a new success notification
     * @param msg Message to display
     * @param action User action to display
     * @param event Callback for user action
     */
    public success(msg: string, action?: string, event?: () => void) {
        const message = msg ? msg : `Success`;
        this.overlay.notify(
            'success',
            {
                html: `<div class="display-icon success" style="font-size:2.0em"></div><div class="msg">${message}</div>`,
                name: 'ntfy success',
                action,
            },
            event
        );
    }

    /**
     * Create a new informational notification
     * @param msg Message to display
     * @param action User action to display
     * @param event Callback for user action
     */
    public info(msg: string, action?: string, event?: () => void) {
        const message = msg ? msg : `Information`;
        this.overlay.notify(
            'info',
            {
                html: `<div class="display-icon info" style="font-size:2.0em"></div></div><div class="msg">${message}</div>`,
                name: 'ntfy info',
                action,
            },
            event
        );
    }

    get iOS() {
        return Utils.isMobileSafari();
    }

    public getSystem(id: string) {
        const system_list = this.subjects.systems.getValue();
        if (system_list) {
            for (const system of system_list) {
                if (system.id === id) {
                    return system;
                }
            }
        }
        return {};
    }

    private addSystems(list: any[]) {
        const system_list = this.subjects.systems.getValue().concat(list);
        system_list.sort((a, b) => a.name.localeCompare(b.name));
        this.subjects.systems.next(system_list);
    }

    private loadSystems(tries: number = 0) {
        if (this.timers.system) {
            clearTimeout(this.timers.system);
            this.timers.system = null;
        }
        if (tries > 20) {
            return;
        }
        const systems = this.systems.resources.get('System');
        if (systems) {
            tries = 0;
            systems.get({ offset: '0', limit: 500 }).then(
                (sys_list: any) => {
                    this.subjects.systems.next([]);
                    if (sys_list) {
                        const count = sys_list.total;
                        if (count > 500) {
                            const iter = Math.ceil((count - 500) / 500);
                            for (let i = 0; i < iter; i++) {
                                systems.get({ offset: (i + 1) * 500, limit: 500 }).then((list: any) => {
                                    if (list) {
                                        this.addSystems(list.results);
                                    }
                                });
                            }
                        }
                        this.addSystems(sys_list.results);
                    } else {
                        this.timers.system = setTimeout(() => this.loadSystems(tries), 200 * ++tries);
                    }
                },
                (err: any) => {
                    this.timers.system = setTimeout(() => this.loadSystems(tries), 200 * ++tries);
                }
            );
        } else {
            this.timers.system = setTimeout(() => this.loadSystems(tries), 200 * ++tries);
        }
    }

    private checkCache() {
        if (this.version.isEnabled) {
            this.settings.log('SYSTEM', 'Checking cache for updates');
            this.version
                .checkForUpdate()
                .then(() => this.settings.log('SYSTEM', 'Finished checking cache for updates'))
                .catch((err) => this.settings.log('SYSTEM', err, null, 'error'));
        }
    }
}
