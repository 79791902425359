/**
 * @Author: Alex Sorafumo
 * @Date:   17/10/2016 4:10 PM
 * @Email:  alex@yuion.net
 * @Filename: simple.component.ts
 * @Last modified by:   Alex Sorafumo
 * @Last modified time: 01/02/2017 1:37 PM
 */

import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AppService } from '../../services/app.service';

import * as moment from 'moment';

@Component({
    selector: 'app-control',
    styleUrls: ['./control.style.scss'],
    templateUrl: './control.template.html'
})
export class ControlComponent implements OnInit, OnDestroy {
    /** ID of the system to bind to */
    @Input() public system: string;

    public model: any = {};

    private timers: any = {};

    constructor(private app_service: AppService, private _router: Router) {
    }

    public get recording() {
        return this.model.recording || {};
    }

    public ngOnInit() {
        this.model.recording = {};
        this.model.show = {};
        setTimeout(() => this.model.started = true, 500);
        this.timers.time = setInterval(() => this.update(), 1000);
    }

    public ngOnDestroy() {
        if (this.timers.time) {
            clearInterval(this.timers.time);
            this.timers.time = null;
        }
    }

    public updateInputs() {
        this.updateOutputs();
    }

    public updateOutputs() {
        if (this.model.outputs) {
            for (const item of this.model.outputs) {
                item.route = this.model.routes ? this.model.routes[item.name] || {} : {};
                item.icon = null;
                if (item.route) {
                    // item.route.showing = !item.route.muted;
                    item.showing = item.route.showing;
                }
                if (item.route && item.route.input && this.model.inputs) {
                    for (const i of this.model.inputs) {
                        if (i.name === item.route.input) {
                            item.icon = i.icon;
                            break;
                        }
                        if (i.children) {
                            for (const j of i.children) {
                                if (j.name === item.route.input) {
                                    item.icon = j.icon;
                                }
                            }
                        }
                    }
                }
            }
        }
        this.processAVMode();
    }

    public processRecording() {
        console.log('Recording:', this.model.recording);
        if (this.model.recording && this.model.recording.next) {
            const start = moment(this.model.recording.next.start_time);
            this.model.recording.next.start = start.format('h:mma');
            const pre = moment(start).add(-15, 'm');
            const now = moment();
            this.model.recording.next.show = now.isBetween(pre, start, 'm');
        }
        if (this.model.recording && this.model.recording.current) {
            const start = moment(this.model.recording.current.start_time);
            this.model.recording.current.start = start.format('h:mma');
        } else if (this.model.recording.previous !== this.model.recording.current && !this.model.recording.current) {
            this.app_service.Overlay.clear();
        }
        this.model.recording.previous = this.model.recording.current;
    }

    /** Preview can be a hash or an array */
    public processPreview(preview) {
        if (!preview) { return; }
        if (Array.isArray(preview)) {
            this.model.preview = preview;
        } else {
            this.model.preview = ['preview'];
        }
    }

    public clear() {
        this.app_service.Overlay.clear();
    }

    public update() {
        if (this.model.recording && this.model.recording.next) {
            const start = moment(this.model.recording.next.start_time);
            const pre = moment(start).add(-15, 'm');
            const now = moment();
            this.model.recording.next.show = now.isBetween(pre, start, 'm', '(]');
        }
    }

    public changeSource(output: any) {
        this.app_service.Overlay.openModal('select-source', { data: { output } })
            .then((inst: any) => inst.subscribe((event) => {
                if (event.type === 'Select') {
                    this.setRoute(event.data.input, output.name);
                }
                event.close();
            }));
    }

    public setRoute(input: string, output: string) {
        if (this.timers[`route|${output}`]) {
            clearTimeout(this.timers[`route|${output}`]);
            this.timers[`route|${output}`] = null;
        }
        this.timers[`route|${output}`] = setTimeout(() => {
            this.model.in = input;
            this.model.out = output;
            this.model.present = !this.model.present;
        }, 200);
    }

    public makeCall() {
        if (this.model.av_mode) { return; }
        this.app_service.Overlay.openModal('dial-directory', { data: {}}, (e) => {
            if (e.type === 'Select') {
                this.model.selected = e.data.selected;
                const selected = this.model.selected ?
                    this.model.selected.email ||
                    this.model.selected.phone ||
                    this.model.selected.number ||
                    this.model.selected.methods[0].number :
                    this.model.search;
                this.model.dial_number = !this.model.dial_number;
                setTimeout(() => {
                    this.model.search = '';
                    this.model.selected = null;
                    this._router.navigate([], { queryParams: { page: 'vidconf', selected } });
                }, 100);
            }
            e.close();
        });
    }

    public processAVMode() {
        if (!this.model.pc_av) { return; }
        this.model.av_mode = this.model.pc_av.enabled;
        if (this.model.pc_av.enabled) {
            const sources = this.model.pc_av.sources;
            if (!sources) { return; }
            for (const source of sources) {
                this.setRoute(source.input, source.output);
            }
            for (const item of this.model.outputs) {
                const disabled_outputs = this.model.pc_av.sources.map(i => i.output);
                item.disabled = disabled_outputs.find(i => i === item.name);
            }
        } else {
            for (const output of this.model.outputs) {
                output.disabled = false;
            }
        }
    }

    public toggleAVMode() {
        this.model.new_av_mode = !this.model.av_mode;
        this.model.change_pc_av = !this.model.change_pc_av;
        setTimeout(() => {
            this.model.new_av_mode = '';
        }, 100);
    }

    public toggleChildren(item: any, preview: string) {
        if (this.model.show[preview] === item.name) {
            this.model.show[preview] = '';
        } else {
            this.model.show = {};
            this.model.show[preview] = item.name;
        }
    }

    public startSelect() {
        this.model.selecting = true;
        setTimeout(() => this.model.selecting = false, 300);
    }

    public isActive(item: any, preview: string): boolean {
        const routes = this.model.routes || {};
        const preview_item = routes[preview] || {};
        return preview_item.input === item.name ||
            item.children && item.children.map(i => i.name).includes(preview_item.input);
    }

    public selectChild(item: any, preview: string) {
        if (this.model.selecting) {
            this.setRoute(item.name, preview);
        }
    }

    public setState(output: any, state: boolean) {
        console.log('Change state:', state);
        output.show = state;
    }

}
