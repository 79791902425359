
import { AppShellComponent } from './shell.component';
import { ControlComponent } from './control/control.component';
import { PreControlComponent } from './pre-control/pre-control.component';
import { VideoConfComponent } from './video-conf/video-conf.component';
import { SystemPanelSelectComponent } from './panel-select/panel-select.component';

export * from './shell.component';
export * from './control/control.component';
export * from './pre-control/pre-control.component';

export const APP_COMPONENTS: any[] = [
    AppShellComponent,
    ControlComponent,
    PreControlComponent,
    VideoConfComponent,
    SystemPanelSelectComponent
];
