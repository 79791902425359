import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AppService } from '../../services/app.service';

@Component({
    selector: 'app-video-conf',
    templateUrl: './video-conf.template.html',
    styleUrls: ['./video-conf.styles.scss'],
})
export class VideoConfComponent implements OnInit {
    /** ID of the system to bind to */
    @Input() public system: string;

    public model: any = {};

    private timers: any = {};

    constructor(private service: AppService, private route: ActivatedRoute, private _router: Router) {
        this.route.queryParamMap.subscribe((params) => {
            if (params.has('selected')) {
                this.model.connected = params.get('selected');
            }
        });
    }

    public ngOnInit() {
        this.model.control = 'dialing';
        this.model.inputs = [];
        this.model.show = {};
        this.model.vc_present = {
            icon: 'ban',
            name: 'Presenter',
            route: { input: 'Off', muted: false, showing: true },
        };
        setTimeout(() => (this.model.started = true), 500);
    }

    public setRoute(input: string, output: string) {
        this.model.in = input;
        this.model.out = output;
        this.model.preview_present = !this.model.preview_present;
    }

    public changeSource(output: any) {
        this.service.Overlay.openModal('select-source', { data: { output } }).then((inst: any) =>
            inst.subscribe((event) => {
                if (event.type === 'Select') {
                    console.log('Event:', event);
                    for (const i of this.model.inputs) {
                        if (event.data.input === i.name) {
                            this.model.input = this.model.inputs.indexOf(i);
                            this.model.vc_present.icon = i.icon;
                            this.model.vc_present.route.input = i.name;
                            this.model.vc_source = i.name;
                            break;
                        }
                    }
                }
                event.close();
            })
        );
    }

    public updateSource() {
        if (this.model.inputs) {
            for (const i of this.model.inputs) {
                if (this.model.vc_source === i.name) {
                    this.model.input = this.model.inputs.indexOf(i);
                    this.model.vc_present.icon = i.icon;
                    this.model.vc_present.route.input = i.name;
                    break;
                }
            }
        }
    }

    public confirmHangup() {
        this.service.Overlay.openModal('confirm', {
            data: { message: 'Are you sure you want to hangup the Video Conference?' },
        }).then((inst: any) =>
            inst.subscribe((event) => {
                if (event.type === 'Confirm') {
                    this.model.hangup = Math.random();
                    setTimeout(() => {
                        this._router.navigate([], { queryParams: { page: 'presentation' } });
                    }, 100);
                }
                event.close();
            })
        );
    }

    public dialSearch() {
        this.model.selected = null;
        this.model.dial_results = [];
        if (this.timers.dial_search) {
            clearTimeout(this.timers.dial_search);
            this.timers.dial_search = null;
        }
        this.timers.dial_search = setTimeout(() => {
            if (this.model.search && this.model.search.length >= 1 && !this.model.searching) {
                this.model.dial_search = !this.model.dial_search;
            }
            this.timers.dial_search = null;
        }, 300);
    }

    public dial() {
        this.model.dial_number = !this.model.dial_number;
        setTimeout(() => {
            this.model.search = '';
            this.model.selected = null;
        }, 100);
    }

    public setDTMF(num: string) {
        this.model.dtmf_change = Date.now();
        this.model.dtmf = num;
        console.log(`DTMF: "${this.model.dtmf}"`);
    }

    public camCheck(cameras: any) {
        if (!cameras) {
            return;
        }
        this.model.camera_names = [];
        for (const cam of cameras) {
            this.model.camera_names.push(cam.title || cam.name);
        }
    }

    public updateCamera() {
        if (this.model.selected_camera && this.model.cameras) {
            const check = this.model.cam;
            this.model.camera_index = this.model.cameras.findIndex((i) => check === i.title || check === i.name);
        }
    }

    public stopCamera(camera: any) {
        camera.tilt = 'stop';
        camera.pan = 'stop';
    }

    public adjustCamera(camera: any, type: string, dir: string) {
        camera[type] = dir;
    }

    public setHold() {
        if (!this.model.inited) {
            this.model.on_hold = this.model.status && this.model.status.status === 'OnHold';
            this.model.inited = true;
        }
        this.model.is_holding = this.model.status && this.model.status.status === 'OnHold';
    }

    public updateHold(state: boolean) {
        this.model.on_hold = state;
    }

    public processResults() {
        if (!this.model.search || !this.model.results) {
            return;
        }
        this.model.dial_results = this.model.results;
    }

    public select(item) {
        this.model.selected = item;
    }

    /**
     * Check if VC call is still in progress
     * @param active in call state of the call
     */
    public checkCall(active: boolean) {
        if (this.timers.check_call) {
            clearTimeout(this.timers.check_call);
        }
        this.timers.check_call = setTimeout(() => {
            if (!active) {
                this._router.navigate([], { queryParams: { page: 'presentation' } });
            }
            this.timers.check_call = null;
        }, 1000);
    }

    /** Preview can be a hash or an array */
    public processPreview(preview) {
        if (!preview) {
            return;
        }
        if (Array.isArray(preview)) {
            this.model.preview = preview;
        } else {
            this.model.preview = ['preview'];
        }
    }

    public toggleChildren(item: any, preview: string) {
        if (this.model.show[preview] === item.name) {
            this.model.show[preview] = '';
        } else {
            this.model.show = {};
            this.model.show[preview] = item.name;
        }
    }

    public startSelect() {
        this.model.selecting = true;
        setTimeout(() => (this.model.selecting = false), 300);
    }

    public selectChild(item: any, preview: string) {
        if (this.model.selecting) {
            this.setRoute(item.name, preview);
        }
    }

    public isActive(item: any, preview: string): boolean {
        const routes = this.model.routes || {};
        const preview_item = routes[preview] || {};
        return (
            preview_item.input === item.name ||
            (item.children && item.children.map((i) => i.name).includes(preview_item.input))
        );
    }
}
