
import { OverlayContentComponent } from '@acaprojects/ngx-widgets';
import { Component } from '@angular/core';

@Component({
    selector: 'confirm-modal',
    templateUrl: './confirm.template.html',
    styleUrls: ['./confirm.styles.scss']
})
export class ConfirmModalComponent extends OverlayContentComponent {

}
