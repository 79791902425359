
import { OverlayContentComponent } from '@acaprojects/ngx-widgets';
import { Component } from '@angular/core';
import { Utils } from '../../shared/utility.class';

@Component({
    selector: 'dial-directory-modal',
    templateUrl: './dial-directory-modal.template.html',
    styleUrls: ['./dial-directory-modal.styles.scss']
})
export class DialDirectoryModalComponent extends OverlayContentComponent {
    /** List of available users in the phone book directory */
    public groups: any[];
    /** List of filtered users in the phone book directory */
    public user_list: any[];
    /** List of filtered users in the phone book directory */
    public filtered_items: any[];
    /** ID of the active group */
    public group_id: string;
    /** Active group to display */
    public get active_group(): any {
        return this.groups.find(i => i.local_id === this.group_id);
    }

    protected subs: any = { timers: {} };

    public init() {
        this.service.listen('system', (sys) => {
            this.model.system = sys;
        });
    }

    public processResults() {
        this.model.searching = false;
        this.clearTimer('search_timeout');
        if (!this.groups) { return; }
        this.groups.forEach(i => {
            i.items = i.contents.map(i => {
                i.number = `${i.number || i.phone || i.methods[0].number || ''}`;
                return i;
            }).sort((a, b) => (a.name || '').localeCompare(b.name));
        });
        this.filter(this.model.search)
    }

    public filter(search: string) {
        this.user_list = (this.groups || []).map(grp => {
            grp = { ...grp };
            grp.items = Utils.filter(search, grp.items, ['name', 'number']);
            grp.items.forEach(i => {
                i[`match_name`] = (i[`match_name`] || '').replace(/\`[a-zA-Z0-9\@\.\_]*\`/g, '<span class="highlight">$&</span>');
                i[`match_name`] = i[`match_name`].replace(/\`/g, '');
                i[`match_number`] = (i[`match_number`] || '').replace(/\`[a-zA-Z0-9\@\.\_]*\`/g, '<span class="highlight">$&</span>');
                i[`match_number`] = i[`match_number`].replace(/\`/g, '');
            })
            return grp;
        });
        this.filtered_items = this.user_list.reduce((a, v) => a.concat(v.items || []), []);
    }

    public select(item) {
        this.model.selected = item;
        this.event('Select');
    }

    public timeout(name: string, fn: () => void, delay: number = 300) {
        this.clearTimer(name);
        if (!(fn instanceof Function)) { return; }
        this.subs.timers[name] = setTimeout(() => fn(), delay);
    }

    public clearTimer(name: string) {
        if (this.subs.timers[name]) {
            clearTimeout(this.subs.timers[name]);
            this.subs.timers[name] = null;
        }
    }

}
