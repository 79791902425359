
import { OverlayContentComponent } from '@acaprojects/ngx-widgets';
import { Component } from '@angular/core';

@Component({
    selector: 'change-lighting-modal',
    templateUrl: './change-lighting.template.html',
    styleUrls: ['./change-lighting.styles.scss']
})
export class ChangeLightingModalComponent extends OverlayContentComponent {

    public init() {
        this.service.listen('system', (sys) => {
            this.model.system = sys;
        });
    }

    public sort() {
        console.log('Model:', this.model);
        if (this.model.levels) {
            this.model.levels.sort((a, b) => a.level - b.level);
        }
    }

    public select(item: any) {
        this.model.active = item.id;
        this.fn.close();
    }
}
