
import { OverlayContentComponent } from '@acaprojects/ngx-widgets';
import { Component, ViewChild } from '@angular/core';
import { trigger, transition, style, animate, group, state } from '@angular/animations';

@Component({
    selector: 'select-source-modal',
    templateUrl: './select-source.template.html',
    styleUrls: ['./select-source.styles.scss'],
    animations: [
        trigger('show', [
            state('in', style({height: '*', opacity: 0})),
            transition(':enter', [
                style({ height: '0' }),
                group([
                    animate(300, style({ height: '*' })),
                    animate('100ms ease-in-out', style({ opacity: 1 }))
                ])
            ]),
            transition(':leave', [
                style({ height: '*' }),
                group([
                    animate(300, style({ height: 0 })),
                    animate('100ms ease-in-out', style({ opacity: 0 }))
                ])
            ]),
        ]),
    ]
})
export class SelectSourceModalComponent extends OverlayContentComponent {
    @ViewChild('inputs') inputs;

    public init() {
        this.service.listen('system', (sys) => {
            this.model.system = sys;
        });
        console.log('Model:', this.model);
    }

    public startSelect() {
        this.model.selecting = true;
        setTimeout(() => this.model.selecting = false, 300);
    }

    public select(item: any) {
        if (this.model.selecting) {
            this.model.input = item.name;
            this.fn.event('Select');
        }
    }

    public toggleChildren(item: any, index: number, event) {
        if (this.model.selecting) {
            item.show = !item.show;
            if (item.show) {
                const element: HTMLElement = event.target;
                setTimeout(() => {
                    this.inputs.nativeElement.scrollTo({
                        top: index * element.clientHeight,
                        left: 0,
                        behavior: 'smooth'
                    });
                }, 100);
            }
        }
    }

    /** Check if child is active for styling */
    public checkActive() {
        if (this.model.inputs && this.model.output.route) {
            this.model.inputs.forEach(input => {
                if (
                    input.children &&
                    input.children.map(i => i.name).includes(this.model.output.route.input)
                ) {
                    input.active = true;
                } else {
                    input.active = false;
                }
            });
        };
    }
}
