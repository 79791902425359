/**
 * @Author: Alex Sorafumo
 * @Date:   17/10/2016 4:10 PM
 * @Email:  alex@yuion.net
 * @Filename: simple.component.ts
 * @Last modified by:   Alex Sorafumo
 * @Last modified time: 01/02/2017 1:37 PM
 */

import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, OnDestroy, Input, SimpleChanges } from '@angular/core';

import { AppService } from '../services/app.service';

import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { Utils } from '../shared/utility.class';

@Component({
    selector: 'app-shell',
    styleUrls: ['./shell.styles.scss'],
    templateUrl: './shell.template.html',
    animations: [
        trigger('show', [
            transition(':enter', [
                style({ opacity: 0, transform: 'translate(-50%, -100%)' }),
                animate(300, style({ opacity: 1, transform: 'translate(-50%, 0%)' })),
            ]),
            transition(':leave', [
                style({ opacity: 1, transform: 'translate(-50%, 0%)' }),
                animate(300, style({ opacity: 0, transform: 'translate(-50%, -100%)' })),
            ]),
        ]),
    ],
})
export class AppShellComponent implements OnInit, OnDestroy {
    /** ID of the system to bind to */
    @Input() public system: string;
    /** Current page to show on the view */
    public page: string;

    public current_booking: any;
    public has_vc = false;

    public model: any = {};
    public logo: any = {};
    public timers: any = {};

    constructor(private app_service: AppService, private _route: ActivatedRoute, private _router: Router) {
        this.model.volume = {};
    }

    public ngOnInit() {
        this.model.recording = {};
        this.model.year = moment().format('YYYY');

        // Workaround to not shrink window when keyboard appears on android devices
        setTimeout(function () {
            let viewheight = window.innerHeight;
            let viewwidth = window.innerWidth;
            let viewport = document.querySelector('meta[name=viewport]');
            viewport.setAttribute(
                'content',
                'height=' + viewheight + 'px, width=' + viewwidth + 'px, initial-scale=1.0'
            );
        }, 300);
        this.init();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.system && this.system) {
            this.model.system = this.system;
        }
    }

    public ngOnDestroy() {
        if (this.timers.time) {
            clearInterval(this.timers.time);
            this.timers.time = null;
        }
    }

    public init() {
        if (!this.app_service.Settings.setup) {
            return setTimeout(() => this.init(), 200);
        }
        this.app_service.listen('system', (sys) => (this.model.system = sys));
        this._route.queryParamMap.subscribe((params) => {
            if (params.has('page')) {
                this.page = params.get('page');
            }
        });
        this.logo.type = this.app_service.Settings.get('app.logo_type');
        this.logo.src = this.app_service.Settings.get('app.logo');
        this.initTime();
    }

    public initTime() {
        const now = moment();
        const delay = 60 * 1000 - (now.seconds() * 1000 + now.milliseconds()) + 1000;
        this.model.datetime = now.format('dddd, Do MMMM YYYY, h:mma');
        setTimeout(() => {
            this.model.datetime = moment().format('dddd, Do MMMM YYYY, h:mma');
            this.processRecording();
            this.timers.time = setInterval(() => {
                this.model.datetime = moment().format('dddd, Do MMMM YYYY, h:mma');
                this.processRecording();
            }, 60 * 1000);
        }, delay);
    }

    public processRecording() {
        console.log('Recording:', this.model.recording);
        if (this.model.recording && this.model.recording.next) {
            const start = moment(this.model.recording.next.start_time);
            this.model.recording.next.start = start.format('h:mma');
            const pre = moment(start).add(-15, 'm');
            const now = moment();
            this.model.recording.next.show = now.isBetween(pre, start, 'm', '[]');
            console.log('Recording:', this.model.recording.next);
        }
    }

    public changeLighting() {
        this.app_service.Overlay.openModal('change-lighting', { data: {} }).then(
            (inst: any) =>
                inst.subscribe((event) => {
                    event.close();
                }),
            (err) => null
        );
    }

    public help() {
        if (this.model.help) {
            this.app_service.Overlay.openModal('help-video', {
                data: { message: this.model.help, videos: this.model.help_video },
            }).then((inst: any) => inst.subscribe((event) => event.close()));
        }
    }

    public openDialPad() {
        this.app_service.Overlay.openModal('dialpad').then((inst: any) => inst.subscribe((event) => event.close()));
    }

    public viewBookings() {
        this.app_service.Overlay.openModal('join-bookings').then((inst: any) =>
            inst.subscribe((event) => event.close())
        );
    }

    public pause(state: string) {
        if (state === 'active') {
            this.model.recording.active = Date.now();
        } else {
            this.model.recording.paused = Date.now();
        }
    }

    public stopRecording() {
        this.app_service.Overlay.openModal('confirm', {
            data: {
                message: `<p>Are you sure you want to end your recording?</p>
            <p>You are about to end your Mediasite recording.
            <strong>Once a recording has ended, there is no way of restarting it.</strong>
            Please consider pausing your recording if you want to temporarily stop it.</p>
            <p><strong>This action cannot be undone</strong></p>`,
                cancel: 'Cancel',
                confirm: 'Ok',
            },
        }).then((inst: any) =>
            inst.subscribe((event) => {
                console.log('Event:', event);
                if (event.type === 'Confirm') {
                    this.model.recording.stop = !this.model.recording.stop;
                }
                event.close();
            })
        );
    }

    public answerCall() {
        console.log('Answering Call');
        if (
            this.model.call_status &&
            this.model.call_status.answerstate === 'Unanswered' &&
            this.model.call_status.direction === 'Incoming'
        ) {
            console.log('Call to be answered');
            const message = `
<b>Incoming call from ${this.model.call_status.displayname}</b>
<br />Call type: ${this.model.call_status.calltype}
<br />Number: ${this.model.call_status.remotenumber}`;
            this.app_service.Overlay.openModal('confirm', { data: { message, type: 'call' } }).then((inst: any) =>
                inst.subscribe((event) => {
                    if (event.type === 'Confirm') {
                        this.app_service.Systems.exec(this.model.system, 'VidConf', 1, 'call', ['accept']);
                        this.app_service.Systems.exec(this.model.system, 'System', 1, 'powerup', ['vidconf']);
                    } else {
                        this.app_service.Systems.exec(this.model.system, 'VidConf', 1, 'call', ['reject']);
                    }
                    event.close();
                })
            );
        }
    }

    public manageMics() {
        if (this.model.mics) {
            this.app_service.Overlay.openModal('mics', { data: {} }).then((inst: any) =>
                inst.subscribe((event) => event.close())
            );
        }
    }

    public manageCameras() {
        if (this.model.cameras) {
            this.app_service.Overlay.openModal('cameras', { data: { system: this.model.system } }).then((inst: any) =>
                inst.subscribe((event) => event.close())
            );
        }
    }

    public manageBlinds() {
        if (this.model.blinds) {
            this.app_service.Overlay.openModal('blinds', { data: { system: this.model.system } }).then((inst: any) => {
                inst.subscribe((event) => event.close());
            });
        }
    }

    public joinRooms() {
        if (this.model.room_list.length > 1) {
            this.app_service.Overlay.openModal('join-rooms', { data: {} }).then((inst: any) =>
                inst.subscribe((event) => event.close())
            );
        }
    }

    public processRooms() {
        if (this.model.rooms) {
            this.model.room_list = [];
            const keys = Object.keys(this.model.rooms);
            for (const k of keys) {
                if (this.model.rooms.hasOwnProperty(k)) {
                    this.model.room_list.push({ id: k, name: this.model.rooms[k] });
                }
            }
        }
    }

    public shutdown() {
        this.app_service.Overlay.openModal('confirm', {
            data: { message: `Are you sure you want to shutdown ${this.model.heading}?` },
        }).then((inst: any) =>
            inst.subscribe((event) => {
                if (event.type === 'Confirm') {
                    this.model.shutdown = !this.model.shutdown;
                }
                event.close();
            })
        );
    }

    public toggleMicMute() {
        if (this.timers.mic_mute) {
            clearTimeout(this.timers.mic_mute);
            this.timers.mic_mute = null;
        }
        this.timers.mic_mute = setTimeout(() => {
            this.model.mic_mute = !this.model.mic_mute;
            this.model.mic_mute_state = this.model.mic_mute;
            this.timers.mic_mute = null;
        }, 100);
    }

    public toggleMute() {
        if (this.model.audio && this.model.audio.mute) {
            this.model.volume.mute = !this.model.volume.mute;
            this.model.volume.mute_change = !this.model.volume.mute_change;
        } else {
            this.model.volume.value = this.model.volume.min || 0;
        }
    }

    public volumeChange() {
        if (this.model.volume.value !== this.model.previous_volume) {
            console.log('Exec:', this.model.previous_volume, this.model.volume.value);
            this.model.volume.value_change = !this.model.volume.value_change;
            this.model.previous_volume = this.model.volume.value;
        }
    }

    public modeChange() {
        if (this.model.mode) {
            this._router.navigate([], { queryParams: { page: this.model.mode } });
        }
    }

    public toggleMode() {
        console.log('Toggle mode');
        for (const mode of this.model.modes) {
            if (this.model.mode !== mode.id) {
                this.setMode(mode);
                break;
            }
        }
    }

    public setMode(mode: any) {
        console.log('Set mode:', mode.title);
        this.model.new_mode = mode.id;
        setTimeout(() => (this.model.show_modes = false), 50);
    }

    public updateRemaining(time: number) {
        const seconds = time % 60;
        const minutes = Math.floor(time / 60) % 60;
        const hours = Math.floor(time / 60 / 60) % 60;
        this.model.recording.remaining = `${Utils.padZero(hours, 2)}:${Utils.padZero(minutes, 2)}:${Utils.padZero(
            seconds,
            2
        )}`;
    }
}
