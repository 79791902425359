/**
 * @Author: Alex Sorafumo
 * @Date:   17/10/2016 4:10 PM
 * @Email:  alex@yuion.net
 * @Filename: simple.component.ts
 * @Last modified by:   Alex Sorafumo
 * @Last modified time: 01/02/2017 1:37 PM
 */

import { CommsService } from '@acaprojects/ngx-composer';
import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { AppService } from '../../services/app.service';

@Component({
    selector: 'pre-control',
    styleUrls: ['./pre-control.style.scss'],
    templateUrl: './pre-control.template.html',
    animations: [
        trigger('show', [
            transition(':leave', [style({ opacity: 1 }), animate('0.7s ease-in-out', style({ opacity: 0 }))]),
        ]),
    ],
})
export class PreControlComponent {
    public model: any = {};
    constructor(private app_service: AppService, private route: ActivatedRoute) {
        this.route.params.subscribe((params) => {
            if (params.sys_id) {
                this.model.system = params.sys_id;
                this.app_service.system = this.model.system;
            }
        });
    }

    public ngOnInit() {
        this.app_service.listen('system', (sys) => {
            this.model.system = sys;
        });
        setTimeout(() => {
            if (!this.model.system) {
                this.app_service.navigate('bootstrap');
            }
        }, 300);
    }

    public powerup(mode: string = 'presentation') {
        this.model.mode = mode;
        if (this.model.system && this.model.title) {
            this.model.power = !this.model.power;
        }
    }

}
