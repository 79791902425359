import { OverlayContentComponent } from '@acaprojects/ngx-widgets';
import { Component } from '@angular/core';

@Component({
    selector: 'dialpad-modal',
    template: `
        <div class="bg-white rounded overflow-hidden">
            <header class="flex items-center">
                <h3 class="m-2">Zoom Actions</h3>
                <button icon (touchrelease)="fn.close()">
                    <i class="fa fa-close"></i>
                </button>
            </header>
            <main class="flex relative">
                <div zoom class="flex justify-center flex-wrap">
                    <button
                        action
                        class="flex-1"
                        *ngFor="let action of zoom_actions"
                        (click)="dtmf = action.code; dtmf_change = !dtmf_change"
                    >
                        {{ action.name }}
                    </button>
                </div>
                <div dtmf class="">
                    <!-- <input class="p-2 m-2" placeholder="Input number..." /> -->
                    <div class="flex flex-col items-center space-y-2">
                        <div class="flex items-center space-x-2">
                            <button dial-key (click)="dtmf = '1'; dtmf_change = !dtmf_change">1</button>
                            <button dial-key (click)="dtmf = '2'; dtmf_change = !dtmf_change">2</button>
                            <button dial-key (click)="dtmf = '3'; dtmf_change = !dtmf_change">3</button>
                        </div>
                        <div class="flex items-center space-x-2">
                            <button dial-key (click)="dtmf = '4'; dtmf_change = !dtmf_change">4</button>
                            <button dial-key (click)="dtmf = '5'; dtmf_change = !dtmf_change">5</button>
                            <button dial-key (click)="dtmf = '6'; dtmf_change = !dtmf_change">6</button>
                        </div>
                        <div class="flex items-center space-x-2">
                            <button dial-key (click)="dtmf = '7'; dtmf_change = !dtmf_change">7</button>
                            <button dial-key (click)="dtmf = '8'; dtmf_change = !dtmf_change">8</button>
                            <button dial-key (click)="dtmf = '9'; dtmf_change = !dtmf_change">9</button>
                        </div>
                        <div class="flex items-center space-x-2">
                            <button dial-key (click)="dtmf = '*'; dtmf_change = !dtmf_change">*</button>
                            <button dial-key (click)="dtmf = '0'; dtmf_change = !dtmf_change">0</button>
                            <button dial-key (click)="dtmf = '#'; dtmf_change = !dtmf_change">#</button>
                        </div>
                    </div>
                    <div class="m-2"></div>
                </div>
                <div
                    outcall
                    class="absolute inset-0 flex flex-col items-center justify-center space-y-2"
                    *ngIf="!incall"
                >
                    <p>You are not in a call currently</p>
                    <button (click)="makeCall()">Join Zoom Call</button>
                </div>
            </main>
        </div>
        <i binding [value]="dtmf_change" [sys]="system_id" mod="VidConf" exec="send_DTMF" [params]="[dtmf]"></i>
        <i
            binding
            [value]="model.dial_number"
            [sys]="system_id"
            mod="VidConf"
            exec="dial"
            [params]="[
                model.selected
                    ? model.selected.email ||
                      model.selected.phone ||
                      model.selected.number ||
                      model.selected.methods[0].number
                    : model.search
            ]"
        ></i>
    `,
    styles: [
        `
            header {
                background-color: #37474f;
                color: #fff;
                justify-content: space-between;
            }

            [dtmf] {
                padding: 1rem 1rem 0.5rem;
            }

            [dial-key] {
                position: relative;
                padding: 0;
                min-width: 0;
                box-shadow: 0px 4px 0px 0px #ccc;
                width: 3rem;
                height: 3rem;
                border-radius: 8px;
                background: #fff;
                border: 2px solid #ccc;
                transition: top 200ms;
                color: black !important;
            }

            [dial-key]:active {
                top: 4px;
                box-shadow: none;
            }

            [action] {
                min-width: 45%;
                font-size: 0.9rem;
                margin: 0.25rem 0.25rem;
            }

            [zoom] {
                width: 50vw;
                padding: 0.25rem;
            }

            [outcall] {
                background: rgba(255, 255, 255, 0.8);
            }
        `,
    ],
})
export class DialingModalComponent extends OverlayContentComponent {
    public dtmf = '';
    public dtmf_change = false;
    public system_id = '';

    public zoom_actions = [
        { name: 'Video Layout', code: '*11' },
        { name: 'Show Participants', code: '*16' },
        { name: 'Captions On/Off', code: '*108' },
        { name: 'Host Key Prompt', code: '*17' },
        { name: 'Unmute Mic', code: '*12' },
        { name: 'Start/Stop Camera', code: '*14' },
        { name: 'Meeting Info', code: '*19' },
        { name: 'Start/Stop Rec', code: '*15' },
        { name: 'Show DTMF on Main Display', code: '#1' },
        { name: 'Hide DTMF on Main Display', code: '*' },
    ];

    public get incall() {
        return location.search.includes('vidconf') || location.hash.includes('vidconf');
    }

    public init() {
        this.service.listen('system', (sys) => (this.system_id = sys));
    }

    public makeCall() {
        if (this.model.av_mode) return;
        this.model.selected = { email: 'meet@zmau.us' };
        const selected = 'meet@zmau.us';
        this.model.dial_number = !this.model.dial_number;
        setTimeout(() => {
            this.model.search = '';
            this.model.selected = null;
            this.service.navigate([], { page: 'vidconf', selected });
        }, 100);
    }
}
