import { OverlayContentComponent } from '@acaprojects/ngx-widgets';
import { Component } from '@angular/core';
import { AppService } from '../../services/app.service';

interface IBlind {
    /** Module of the blind */
    module: string;
    /** Up function */
    up: {
        func: string,
        args: number[]
    };
    /** Down function */
    down: {
        func: string,
        args: number[]
    };
}

@Component({
    selector: 'blinds-modal',
    templateUrl: './blinds.template.html',
    styleUrls: ['./blinds.styles.scss']
})
export class BlindsModalComponent extends OverlayContentComponent {
    /** Blinds */
    public blinds: { [name: string]: IBlind } = {};
    /** Timer for blind adjustment  */
    private _timer: any;

    /** Adjust blind
     * @param action up or down
     * @param blind blind details
     */
    public adjustBlind(action: string, blind: IBlind, title?: string) {
        this._timer = setTimeout(() => {
            const service: AppService = this.service;
            const module = service.Systems.getModule(this.model.system, blind.module);
            if (module && blind[action]) {
                module.exec(blind[action].func, blind[action].args)
                    .then(() => {
                        this._timer = null;
                    }, () => {
                        this._timer = null;
                    });
            }
        }, 10);
    }
}
