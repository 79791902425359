import { SelectSourceModalComponent } from './select-source/select-source.component';
import { ChangeLightingModalComponent } from './change-lighting/change-lighting.component';
import { ConfirmModalComponent } from './confirm/confirm.component';
import { MicrophoneModalComponent } from './mics/mics.component';
import { JoinRoomModalComponent } from './join-room/join-room.component';
import { CameraControlModalComponent } from './camera-control/camera-control.component';
import { HelpVideoModalComponent } from './help-video/help-video.component';
import { DialDirectoryModalComponent } from './dial-directory-modal/dial-directory-modal.component';
import { BlindsModalComponent } from './blinds/blinds.component';
import { JoinBookingModalComponent } from './bookings-modal.component';
import { DialingModalComponent } from './dialing-modal.component';

export const ENTRY_COMPONENTS: any[] = [
    ChangeLightingModalComponent,
    SelectSourceModalComponent,
    ConfirmModalComponent,
    MicrophoneModalComponent,
    JoinRoomModalComponent,
    CameraControlModalComponent,
    HelpVideoModalComponent,
    DialDirectoryModalComponent,
    BlindsModalComponent,
    JoinBookingModalComponent,
    DialingModalComponent,
];
