
import { OverlayContentComponent } from '@acaprojects/ngx-widgets';
import { Component } from '@angular/core';

import { AppService } from '../../services/app.service';

interface ICamera {
    /** Module associated with the camera data */
    mod: string;
}

@Component({
    selector: 'camera-control-modal',
    templateUrl: './camera-control.template.html',
    styleUrls: ['./camera-control.styles.scss']
})
export class CameraControlModalComponent extends OverlayContentComponent {
    /** Whether a new preset is being added to the camera */
    public adding_preset: boolean;
    /** Timer for  */
    public timer: number;
    /** Timer for  */
    private _timers: { [key: string]: number } = {};
    /** Step value for zooming the camera */
    public step: number = 200;

    public init() {
        this.model.control = 'camera_0';
    }

    public camCheck(cameras: any) {
        if (!cameras) { return; }
        this.model.camera_names = [];
        this.model.camera_index = -1;
        for (const cam of cameras) {
            this.model.camera_names.push(cam.title || cam.name);
        }
    }

    public updateCamera() {
        if (this.model.selected_camera && this.model.cameras) {
            const check = this.model.cam;
            for (const cam of this.model.cameras) {
                const name = cam.title || cam.name;
                if (check === name) {
                    this.model.camera_index = this.model.cameras.indexOf(cam);
                    break;
                }
            }
        }
    }

    public stopCamera(camera: any) {
        camera.tilt = 'stop';
        camera.pan = 'stop';
    }

    public adjustCamera(camera: any, type: string, dir: string) {
        camera[type] = dir;
    }

    /**
     * Add a new preset with the current position and zoom to list
     * @param name Name of the new preset
     */
    public updatePreset(cam: ICamera, name: string) {
        this.timer = <any>setTimeout(() => {
            const service: AppService = this.service;
            const module = service.Systems.getModule(this.model.system, cam.mod);
            if (module) {
                this.adding_preset = true;
                module.exec('save_position', [name])
                    .then(
                        () => {
                            this.model.new_preset = '';
                            this.adding_preset = false;
                            this.timer = null;
                            (this.service as AppService).info(`Updated preset ${name}`, 'OK');
                        },
                        () => {
                            this.adding_preset = false;
                            this.timer = null;
                        }
                    )
            }
        }, 3000);
    }

    /** Handle zooming in on the camera */
    public startZoomIn(camera) {
        this.startZoom(1, camera);
    }

    /** Handle zooming out on the camera */
    public startZoomOut(camera) {
        this.startZoom(-1, camera);
    }

    /**
     * Handle zooming in and out on the camera
     * @param direction Zoom direction. `1` for in and `-1` for out
     * @param camera Camera to update zoom for
     */
    public startZoom(direction: 1 | -1, camera) {
        if (this._timers._start_zoom) {
            clearTimeout(this._timers._start_zoom);
        }
        console.log('Start Zooming:', direction === 1 ? 'In' : 'Out', camera);
        this._timers._start_zoom = <any>setTimeout(() => {
            this._timers._start_zoom = null;
            if (this._timers.zoom) {
                clearInterval(this._timers.zoom);
            }
            camera.zoom += direction * this.step;
            if (camera.zoom > (camera.zoom_max || 100)) {
                camera.zoom = (camera.zoom_max || 100);
            } else if (camera.zoom < (camera.zoom_min || 0)) {
                camera.zoom = (camera.zoom_min || 0);
            }
            this._timers.zoom = <any>setInterval(() => {
                camera.zoom += direction * this.step;
                if (camera.zoom > (camera.zoom_max || 100)) {
                    camera.zoom = (camera.zoom_max || 100);
                } else if (camera.zoom < (camera.zoom_min || 0)) {
                    camera.zoom = (camera.zoom_min || 0);
                }
            }, 1000);

        }, 10);
    }

    /** Cancel any timed zoom events */
    public endZoom() {
        console.log('End Zoom');
        if (this._timers._start_zoom) {
            clearTimeout(this._timers._start_zoom);
            this._timers._start_zoom = null;
        }
        if (this._timers.zoom) {
            clearInterval(this._timers.zoom);
            this._timers.zoom = null;
        }
    }

    public cancelPreset() {
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
    }
}
